export enum LocalStorageKey {
  IsLessThanThreeChannelsDrawerClosed = "isLessThanThreeChannelsDrawerClosed",
  IsSignUpDrawerAfterFirstMessageClosed = "isSignUpDrawerAfterFirstMessageClosed",
  IsAgeConfirmed = "isAgeConfirmed",
  PaymentUid = "paymentUid",
  UserCountry = "userCountry",
  SwipeSupport = "swipeSupport",
  StreamToken = "streamToken",
  CtvVersion = "ctv-version",
  Token = "token",
  RefreshToken = "refreshToken",
  BeforeAuthPage = "beforeAuthPage",
  AfterAuthPage = "afterAuthPage",
  Logger = "logger",
  AuthHash = "authHash",
  RegistrationEmail = "registration-email",
  LastCloseTimestamp = "lastCloseTimestamp",
  FreeVotesCount = "freeVotesCount",
  RedirectParams = "redirectParams",
  AnonPaymentPopup = "anonPaymentPopup",
  Register = "register",
  Login = "login",
  GuestUserId = "guestUserId",
  AnonUserId = "anonUserId",
  User = "user",
  UserId = "userId",
  AfterAnonAuthPage = "afterAnonAuthPage",
  AfterHashAuthPage = "afterHashAuthPage",
  PreviousPage = "previousPage",
}
