import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  list: [],
  visible: false,
};

export const menuDrawerSlice = createSlice({
  name: "menuDrawer",
  initialState,
  reducers: {
    setMenuDrawer: (state, action) => {
      state.list = action.payload;
    },
    setOpenMenuDrawer: (state) => {
      state.visible = true;
    },
    setCloseMenuDrawer: (state) => {
      state.visible = false;
    },
    clearMenuDrawer: () => {
      return initialState;
    },
  },
});

export const {
  setMenuDrawer,
  setOpenMenuDrawer,
  setCloseMenuDrawer,
  clearMenuDrawer,
} = menuDrawerSlice.actions;

export const menuDrawerSelector = (state) => state.menuDrawer;

export const menuDrawerReducer = menuDrawerSlice.reducer;
