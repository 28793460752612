enum WindowSizeCategory {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  "2xl" = "2xl",
}

export const BREAKPOINTS: Record<WindowSizeCategory, number> = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  "2xl": 1536,
};

export const getWindowSizeCategory = (): WindowSizeCategory => {
  const width = window.innerWidth;

  switch (true) {
    case width < BREAKPOINTS.sm:
      return WindowSizeCategory.xs;
    case width < BREAKPOINTS.md:
      return WindowSizeCategory.sm;
    case width < BREAKPOINTS.lg:
      return WindowSizeCategory.md;
    case width < BREAKPOINTS.xl:
      return WindowSizeCategory.lg;
    case width < BREAKPOINTS["2xl"]:
      return WindowSizeCategory.xl;
    default:
      return WindowSizeCategory["2xl"];
  }
};
