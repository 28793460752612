import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  content: undefined,
  loading: true,
  error: undefined,
  hasActiveContent: false,
};

export const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    setContentLoading(state) {
      state.loading = true;
    },
    setContentLoadingSuccess(state, action) {
      state.loading = false;
      state.content = action.payload;
    },
    setContentLoadingError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setContentStatus(state, action) {
      if (state.content && state.content.status) {
        state.content.status = action.payload.status;
      }
    },
    updateVoteCountContent: (state, action) => {
      if (state.content) {
        if (action.payload.vote) {
          state.content.voteCount =
            state.content.voteCount + action.payload.vote;
        }
        state.content.prices.freeVoteDate = {
          ...state.content.prices.freeVoteDate,
          date: action.payload.date ?? state.content.prices.freeVoteDate.date,
        };
      }
    },
    clearContent() {
      return initialState;
    },
    setHasActiveContent: (state, action) => {
      state.hasActiveContent = action.payload;
    },
  },
});

export const {
  setContentLoading,
  setContentLoadingSuccess,
  setContentLoadingError,
  setContentStatus,
  updateVoteCountContent,
  clearContent,
  setHasActiveContent,
} = contentSlice.actions;

export const contentSelector = (state) => state.content;

export const contentReducer = contentSlice.reducer;
