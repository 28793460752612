export enum ServerEvents {
  AppFreeVoteCountdown = "app_free_vote_countdown",
  AppFrontVersion = "app_front_version",
  AppFrontFacebookDisabled = "app_front_facebook_disabled",
  AppFrontEnableRusPayments = "app_front_enable_rus_payments",
}

export interface IServerEvent {
  name: string;
  value: string;
}

export enum NetworkSpeedType {
  Slow = "S",
  Normal = "M",
  Fast = "L",
}
