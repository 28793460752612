import {createSelector, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IUser} from "../interfaces/user";
import {AppState} from "../store";
import {configSelector} from "@app/store/slices/config";
import {isMinWidthXl} from "@utils/detections";
import {LocalStorageKey} from "@appTypes/localstorage";

export type ProfileStoreItem = IUser & {
  loading?: boolean;
  following?: boolean;
  contentLoading?: boolean;
  personalUid?: string;
};

interface IProfileStore {
  isAuthenticated: boolean;
  isCookieConsent: boolean;
  beforeAuthPage?: string;
  afterAuthPage?: string;
  swipeSupport?: string;
  viewed: string[];
  logoutDrawerVisible?: boolean;
  deleteDrawerVisible?: boolean;
  deleteDrawerType?: string;
  deleteContentId?: string;
  reportDrawerVisible?: boolean;
  reportDrawerType?: string;
  reportContentId?: string;
  currentProfile: ProfileStoreItem;
  selectedProfile: ProfileStoreItem;
  isMinWidthXl: boolean;
}

export const initialProfileItemState: ProfileStoreItem = {
  avatar: "",
  challenges: {
    subscribed: [],
    joined: [],
  },
  mysteryCurrentPage: 0,
  mysteryItems: [],
  mysteryLastPage: false,
  contentsAllCurrentPage: 0,
  contentsAllItems: [],
  contentsAllLastPage: false,
  email: "",
  fullName: "",
  isVerified: false,
  isApproved: undefined,
  isAnonym: false,
  isAI: false,
  notificationCount: 0,
  messagesCount: 0,
  payingMessagesCount: 0,
  regularMessagesCount: 0,
  uid: "",
  username: "",
  following: false,
  counters: {
    followers: 0,
    following: 0,
    viewers: 0,
  },
};

const initialState: IProfileStore = {
  isAuthenticated: false,
  isCookieConsent: document.cookie.includes("cookieConsent=true"),
  beforeAuthPage: localStorage.getItem(LocalStorageKey.BeforeAuthPage) || "/",
  afterAuthPage: localStorage.getItem(LocalStorageKey.AfterAuthPage) || "/",
  swipeSupport: localStorage.getItem(LocalStorageKey.SwipeSupport) || "",
  viewed: [],
  currentProfile: initialProfileItemState,
  selectedProfile: initialProfileItemState,
  isMinWidthXl: isMinWidthXl(),
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setIsAnonym: (state, action) => {
      state.currentProfile.isAnonym = action.payload;
    },
    setSwipeSupport: (state, action) => {
      if (state.swipeSupport !== "disabled") {
        state.swipeSupport = action.payload;
      }
    },
    setBeforeAuthPage: (state, action) => {
      state.beforeAuthPage = action.payload;
    },
    setAfterAuthPage: {
      reducer: (
        state,
        action: PayloadAction<string | {path: string; params: {id: string}}>,
      ) => {
        if (typeof action.payload === "string") {
          state.afterAuthPage = action.payload;
        } else {
          const placeholder = action.payload.path.includes(":id")
            ? ":id"
            : ":alias";
          state.afterAuthPage = action.payload.path.replace(
            placeholder,
            action.payload.params.id,
          );
        }
      },
      prepare: (payload: string | {path: string; params: {id: string}}) => ({
        payload,
      }),
    },
    setViewedContent: (state, action) => {
      if (!state.viewed.includes(action.payload)) {
        state.viewed.push(action.payload);
      }
    },
    setLogoutVisible: (state, action) => {
      state.logoutDrawerVisible = action.payload;
    },
    setDeleteVisible: (state, action) => {
      const {visible, type, contentId} = action.payload;
      state.deleteDrawerVisible = visible;
      state.deleteDrawerType = type;
      state.deleteContentId = contentId;
    },
    setReportVisible: (state, action) => {
      const {visible, type, contentId} = action.payload;
      state.reportDrawerVisible = visible;
      state.reportDrawerType = type;
      state.reportContentId = contentId;
    },
    // Current profile
    uploadAvatar: (state, action) => {
      state.currentProfile.avatar = action.payload;
    },
    uploadBgAvatar: (state, action) => {
      state.currentProfile.imageProfile = action.payload;
    },
    uploadAudio: (state, action) => {
      state.currentProfile.audioProfile = action.payload;
    },
    deleteAudio: (state) => {
      state.currentProfile.audioProfile = "";
    },
    setMessageTotalCount: (state, action) => {
      state.currentProfile.messagesCount = action.payload;
    },
    setPayingMessageTotalCount: (state, action) => {
      state.currentProfile.payingMessagesCount = action.payload;
    },
    setRegularMessageTotalCount: (state, action) => {
      state.currentProfile.regularMessagesCount = action.payload;
    },
    setNotificationCount: (state, action) => {
      state.currentProfile.notificationCount = action.payload;
    },
    setProfileSocials: (state, action) => {
      state.currentProfile.socials = action.payload;
    },
    setProfileStatus: (state, action) => {
      state.currentProfile.status = action.payload;
    },
    setProfileCountry: (state, action: PayloadAction<string>) => {
      state.currentProfile.country = action.payload;
    },
    setProfileLanguage: (state, action: PayloadAction<string>) => {
      state.currentProfile.language = action.payload;
    },
    setProfileLoading: (state) => {
      state.currentProfile.loading = true;
    },
    setProfileContentLoading: (state) => {
      state.currentProfile.contentLoading = true;
    },
    setProfileData: (state, action) => {
      state.currentProfile = {
        ...state.currentProfile,
        ...action.payload,
        loading: false,
        isRu: action.payload.country === "RU",
      };
    },
    setContentsAllItems: (state, action) => {
      const loadPage =
        state.currentProfile.contentsAllCurrentPage !== action.payload.nextPage;
      state.currentProfile.contentLoading = false;

      if (!loadPage) {
        state.currentProfile.contentsAllItems = action.payload?.items || [];
      } else {
        state.currentProfile.contentsAllItems = [
          ...state.currentProfile.contentsAllItems,
          ...(action.payload?.items || []),
        ];
      }

      state.currentProfile.contentsAllLastPage = action.payload.count === 0;
      state.currentProfile.contentsAllCurrentPage = action.payload.nextPage;
    },
    setNewMysteryItem: (state, action) => {
      state.currentProfile.mysteryItems = [
        action.payload,
        ...state.currentProfile.mysteryItems,
      ];
    },
    setMysteryItems: (state, action) => {
      const items = action.payload?.items || [];
      const loadPage =
        state.currentProfile.mysteryCurrentPage !== action.payload.nextPage;
      state.currentProfile.contentLoading = false;

      if (!loadPage) {
        state.currentProfile.mysteryItems = items;
      } else {
        state.currentProfile.mysteryItems = [
          ...state.currentProfile.mysteryItems,
          ...items,
        ];
      }

      state.currentProfile.mysteryLastPage = action.payload.count === 0;
      state.currentProfile.mysteryCurrentPage = action.payload.nextPage;
    },
    setMysteryDelete: (state, action) => {
      state.currentProfile.mysteryItems =
        state.currentProfile.mysteryItems.filter(
          (item) => item.uid !== action.payload,
        );
    },
    setMysteryStatus: (state, action) => {
      const mystery = state.currentProfile.mysteryItems.find(
        (item) => item.uid === action.payload.id,
      );

      if (mystery) {
        mystery.status = action.payload.status;
      }
    },
    setProfileContentDelete(state, action) {
      state.currentProfile.contentsAllItems =
        state.currentProfile.contentsAllItems.filter(
          (item) => item.uid !== action.payload,
        );
      // state.currentProfile.contentsActiveItems = state.currentProfile.contentsActiveItems.filter((item) => item.uid !== action.payload);
    },
    setProfileContentStatus(state, action) {
      const contentFromAll = state.currentProfile.contentsAllItems.find(
        (item) => item.uid === action.payload.videoId,
      );
      // const contentFromActive = state.currentProfile.contentsActiveItems.find((item) => item.uid === action.payload.videoId);

      if (contentFromAll) {
        contentFromAll.status = action.payload.status;
      }

      // if (contentFromActive) {
      //     contentFromActive.status = action.payload.status;
      // }
    },
    join: (state, action) => {
      const joined = state.currentProfile.challenges.joined;
      const challenge = action.payload;

      if (!joined.some((item) => item === challenge)) {
        state.currentProfile.challenges.joined = [
          ...state.currentProfile.challenges.joined,
          challenge,
        ];
      }
    },
    unjoin: (state, action) => {
      state.currentProfile.challenges.joined =
        state.currentProfile.challenges.joined.filter(
          (item) => item !== action.payload,
        );
    },
    subscribe: (state, action) => {
      const subscribed = state.currentProfile.challenges.subscribed;
      const challenge = action.payload;

      if (!subscribed.some((item) => item === challenge)) {
        state.currentProfile.challenges.subscribed = [
          ...state.currentProfile.challenges.subscribed,
          challenge,
        ];
      }
    },
    unsubscribe: (state, action) => {
      const challenge = action.payload;
      state.currentProfile.challenges.subscribed =
        state.currentProfile.challenges.subscribed.filter(
          (item) => item !== challenge,
        );
    },
    updateProfileContentDescription: (state, action) => {
      const content = state.currentProfile.contentsAllItems.find(
        (item) => item.uid === action.payload.contentId,
      );
      if (content) {
        content.description = action.payload.description;
      }
    },
    // Selected profile
    setSelectedProfileLoading: (state) => {
      state.selectedProfile.loading = true;
    },
    setSelectedProfileContentLoading: (state) => {
      state.selectedProfile.contentLoading = true;
    },
    setSelectedProfileData: (state, action) => {
      state.selectedProfile = {
        ...state.selectedProfile,
        ...action.payload,
        loading: false,
      };
    },
    setSelectedProfileContentsAllItems: (state, action) => {
      const loadPage =
        state.selectedProfile.contentsAllCurrentPage !==
        action.payload.nextPage;
      state.selectedProfile.contentLoading = false;

      if (!loadPage) {
        state.selectedProfile.contentsAllItems = action.payload?.items || [];
      } else {
        state.selectedProfile.contentsAllItems = [
          ...state.currentProfile.contentsAllItems,
          ...(action.payload?.items || []),
        ];
      }

      state.selectedProfile.contentsAllLastPage = action.payload.count < 15;
      state.selectedProfile.contentsAllCurrentPage = action.payload.nextPage;
    },
    setSelectedProfileMysteryItems: (state, action) => {
      const loadPage =
        state.selectedProfile.mysteryCurrentPage !== action.payload.nextPage;
      state.selectedProfile.contentLoading = false;

      if (!loadPage) {
        state.selectedProfile.mysteryItems = action.payload?.items || [];
      } else {
        state.selectedProfile.mysteryItems = [
          ...state.selectedProfile.mysteryItems,
          ...(action.payload?.items || []),
        ];
      }

      state.selectedProfile.mysteryLastPage = action.payload.count < 15;
      state.selectedProfile.mysteryCurrentPage = action.payload.nextPage;
    },
    updateMysteryBoxItem: (state, action) => {
      const mysteryBox = state.currentProfile.mysteryItems?.find(
        (item) => item.uid === action.payload.uid,
      );

      if (mysteryBox) {
        mysteryBox.media = action.payload.media;
        mysteryBox.description = action.payload.description;
        mysteryBox.previewUid = action.payload.previewUid;
        mysteryBox.type = action.payload.type;
        mysteryBox.status = action.payload.status;
        mysteryBox.isActive = action.payload.isActive;
        mysteryBox.isEditable = action.payload.isEditable;
        mysteryBox.preview = action.payload.preview;
        mysteryBox.videoCount = action.payload.videoCount;
        mysteryBox.imageCount = action.payload.imageCount;
        mysteryBox.audioCount = action.payload.audioCount;
        mysteryBox.price = action.payload.price;
      }
    },
    updateVoteCountProfileContents: (state, action) => {
      const {vote, contentId} = action.payload;
      const currentContent = state.currentProfile?.contentsAllItems?.find(
        (item) => item.uid === contentId,
      );
      const selectedContent = state.selectedProfile?.contentsAllItems?.find(
        (item) => item.uid === contentId,
      );

      if (currentContent) {
        if (vote) {
          currentContent.voteCount = currentContent.voteCount + vote;
        }
        currentContent.prices.freeVoteDate = {
          ...currentContent.prices.freeVoteDate,
          date: action.payload.date ?? currentContent.prices.freeVoteDate?.date,
        };
      }

      if (selectedContent) {
        selectedContent.voteCount = selectedContent.voteCount + vote;
        selectedContent.prices.freeVoteDate = {
          ...selectedContent.prices.freeVoteDate,
          date: action.payload.date,
        };
      }
    },

    setSelectedProfileFollowing: (state, action: PayloadAction<boolean>) => {
      const followersCount = state.selectedProfile.counters.followers;

      state.selectedProfile.following = action.payload;

      state.selectedProfile.counters.followers = action.payload
        ? followersCount + 1
        : followersCount - 1;
    },

    clearProfile() {
      return initialState;
    },

    clearCurrentProfileContents(state) {
      state.currentProfile = {
        ...state.currentProfile,
        mysteryItems: [],
        contentsAllItems: [],
      };
    },

    clearCurrentProfile(state) {
      state.currentProfile = initialProfileItemState;
    },
    clearSelectedProfile(state) {
      state.selectedProfile = initialProfileItemState;
    },
    setPersonalUid: (state, action) => {
      state.currentProfile.personalUid = action.payload;
    },

    setCookieConsent: (state) => {
      state.isCookieConsent = true;
    },
    setIsMinWidthXl: (state, action: PayloadAction<boolean>) => {
      state.isMinWidthXl = action.payload;
    },
  },
});

export const {
  setIsAuthenticated,
  setIsAnonym,
  setBeforeAuthPage,
  setAfterAuthPage,
  setSwipeSupport,
  setViewedContent,
  setLogoutVisible,
  setDeleteVisible,
  setReportVisible,
  uploadAvatar,
  uploadBgAvatar,
  uploadAudio,
  deleteAudio,
  setMessageTotalCount,
  setNotificationCount,
  setProfileLoading,
  setProfileContentLoading,
  setProfileData,
  setProfileStatus,
  setProfileCountry,
  setProfileLanguage,
  setContentsAllItems,
  setNewMysteryItem,
  setMysteryItems,
  setMysteryDelete,
  setMysteryStatus,
  setProfileContentDelete,
  setProfileContentStatus,
  join,
  unjoin,
  subscribe,
  unsubscribe,
  clearProfile,
  clearCurrentProfileContents,
  updateProfileContentDescription,
  setSelectedProfileLoading,
  setSelectedProfileContentLoading,
  setSelectedProfileData,
  setSelectedProfileContentsAllItems,
  setSelectedProfileMysteryItems,
  updateVoteCountProfileContents,
  updateMysteryBoxItem,
  clearSelectedProfile,
  clearCurrentProfile,
  setSelectedProfileFollowing,
  setPersonalUid,
  setCookieConsent,
  setIsMinWidthXl,
} = profileSlice.actions;

export const profileSelector = (state: AppState) => state.profile;
export const currentProfileSelector = (state: AppState) =>
  state.profile.currentProfile;
export const selectedProfileSelector = (state: AppState) =>
  state.profile.selectedProfile;

export const joinedChallengesSelector = (state: AppState) =>
  state.profile.currentProfile.challenges?.joined;
export const subscribedChallengesSelector = (state: AppState) =>
  state.profile.currentProfile.challenges?.subscribed;

export const viewedContentsSelector = (state: AppState) => state.profile.viewed;

export const isViewedContentSelector = createSelector(
  [viewedContentsSelector],
  (contents) => (contentId?: string) => {
    if (contents.length && contentId) {
      return contents.includes(contentId);
    }

    return false;
  },
);

export const isJoinedByChallengeId = createSelector(
  [joinedChallengesSelector],
  (challenges) => (challengeId: string) => {
    if (challengeId) {
      if (challenges) {
        const joined = challenges?.some((item) => item === challengeId);
        return Boolean(joined);
      }
    }

    return false;
  },
);

export const isSubscribedByChallengeId = createSelector(
  [subscribedChallengesSelector],
  (challenges) => (challengeId: string) => {
    if (challengeId) {
      if (challenges) {
        const subscribed = challenges?.some((item) => item === challengeId);
        return Boolean(subscribed);
      }
    }

    return false;
  },
);

export const isAuthenticatedSelector = createSelector(
  [profileSelector],
  (profile) => {
    return profile.isAuthenticated || profile.currentProfile.isAnonym;
  },
);

export const activeContentSelector = createSelector(
  [selectedProfileSelector],
  (profile) => {
    return profile.contentsAllItems.filter((item) => item.status === "active");
  },
);

export const isFullyAuthenticatedSelector = createSelector(
  [profileSelector],
  (profile) => {
    return profile.isAuthenticated && !profile.currentProfile.isAnonym;
  },
);

export const isCookieConsentSelector = createSelector(
  [profileSelector],
  (profile) => {
    return profile.isCookieConsent;
  },
);

export const isDesktopChatterSelector = createSelector(
  (state: AppState) => state.profile.currentProfile.isApproved,
  (state: AppState) => state.profile.isMinWidthXl,
  (isApproved, isMinWidthXl) => isApproved && isMinWidthXl,
);

export const isRuSelector = createSelector(
  [profileSelector, configSelector],
  (profile, config) => {
    return profile.currentProfile.country === "RU" && config.enableRusPayments;
  },
);

export const profileReducer = profileSlice.reducer;
