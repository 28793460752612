import {initializeApp} from "firebase/app";
import {getMessaging, onMessage} from "firebase/messaging";
import {isInAppBrowser} from "./utils/detections";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = isInAppBrowser() ? undefined : initializeApp(firebaseConfig);

const messaging = app && navigator.serviceWorker && getMessaging(app);

messaging &&
  onMessage(messaging, (payload) => {
    if (Notification.permission === "granted") {
      const notificationTitle = payload.data.title;
      const notificationOptions = {
        body: payload.data.body,
        icon: payload.data.image,
        data: {url: payload.data.url},
      };

      const notification = new Notification(
        notificationTitle,
        notificationOptions,
      );
      notification.onclick = (event) => {
        event.preventDefault();
        window.open(notification.data.url, "_blank");
      };
    }
  });

export default messaging;
