import authService from "@services/authService";
import {clearContentFeed} from "@app/store/slices/contentFeed";
import {clearContentOverlay} from "@app/store/slices/contentOverlay";
import {clearFeed} from "@app/store/slices/feed";
import {clearNotification} from "@app/store/slices/notifications";
import {clearOverlay} from "@app/store/slices/overlay";
import {clearPayments} from "@app/store/slices/payments";
import {clearCurrentProfile, clearProfile} from "@app/store/slices/profile";
import {clearVoteDrawer} from "@app/store/slices/voteDrawer";
import {clearAuthOverlay} from "@app/store/slices/authOverlay";
import LogoutFailed from "@components/notification/templates/logoutFailed";
import {ReactComponent as ErrorIcon} from "@assets/images/notification-error-white.svg";
import {useAppDispatch} from "@app/store/hooks";
import useNotification from "./useNotification";
import Amplitude from "@utils/amplitude";

const useSignOut = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const clearStore = () => {
    dispatch(clearContentFeed());
    dispatch(clearContentOverlay());
    dispatch(clearFeed());
    dispatch(clearNotification());
    dispatch(clearOverlay());
    dispatch(clearPayments());
    dispatch(clearProfile());
    dispatch(clearVoteDrawer());
    dispatch(clearCurrentProfile());
    dispatch(clearAuthOverlay());
  };

  return (
    navigate: () => void,
    setLoading?: (value: boolean) => void,
    eventId?: string,
  ) => {
    if (eventId) {
      Amplitude.track(eventId);
    }
    authService
      .signout()
      .then(() => {
        clearStore();
      })
      .catch((error) => {
        notification.send({
          type: "error",
          text: <LogoutFailed />,
          options: {
            icon: <ErrorIcon />,
            duration: 10000,
          },
        });
      })
      .finally(() => {
        setLoading?.(false);
        navigate();
      });
  };
};

export default useSignOut;
