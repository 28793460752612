import {PaymentProvider} from "@appTypes/payments";

export enum AnalyticSource {
  Feed = "feed",
  Chat = "chat",
  Profile = "profile",
  Landing = "landing",
  Challenge = "challenge",
  ShowcaseChat = "showcase chat",
  ShowcaseProfile = "showcase profile",
  External = "external",
  Other = "other",
}

export enum AnalyticProductType {
  MysteryBox = "mystery box",
  Tips = "tips",
  CustomTips = "custom tips",
  Gift = "gift",
  HotContentUnlocked = "hot content unlocked",
  Votes = "votes",
  OnlineGift = "online gift",
}

export enum AnalyticIDs {
  CountryConfirmed = "Country Confirmed",
  AgeConfirmed = "Age Confirmed",
  LandingPageViewed = "Landing Page Viewed",
  SignUpFormViewed = "SignUp Form Viewed",
  NotificationViewed = "Notification Viewed",
  ChallengesViewed = "Challenges Viewed",
  FeedViewed = "Feed Viewed",
  VideoViewed = "Video Viewed",
  ProfileViewed = "Profile Viewed",
  MessengerViewed = "Messenger Viewed",
  PaymentOfferViewed = "Payment Offer Viewed",

  ErrorShowPage = "error_showpage",
  VerificationSuccessShowPage = "verification_success_showpage",
  SignInForgetClickButton = "signin_forget_clk_btn",
  VoteClick = "vote_click",
  VotePaidSuccessShowPopup = "vote_paid_sucess_showpopup",
  MessengerClickChat = "messenger_clk_chat",
  MessengerChatMysteryBoxUnpackShow = "messenger_chat_mysterybox_unpack_show",
  MessengerChatMysteryBoxUnpackClickButton = "messenger_chat_mysterybox_unpack_clk_btn",
  MessengerChatMysteryBoxMediaShow = "messenger_chat_mysterybox_media_show",
  MessengerChatUserMessageRead = "messenger_chat_user_msg_read",
  AndroidOnboardingPushEnableShow = "android_onboarding_push_enable_show",
  AndroidOnboardingAllowPush = "android_onboarding_allow_push",
  AndroidOnboardingBlockPush = "android_onboarding_block_push",
  IOSOnboardingHomeScreenPopupClose = "ios_onboarding_home_screen_popup_close",
  IOSGetAppPopupShow = "ios_get_app_popup_show",
  IOSGetAppPopupClick = "ios_get_app_popup_clck",
  IOSGetAppPopupClose = "ios_get_app_popup_close",
  MessengerChatPaidTips200ClickButton = "messenger_chat_paid_tips_200_clck_btn",
  MessengerChatPaidTips500ClickButton = "messenger_chat_paid_tips_500_clck_btn",
  MessengerChatPaidTips1000ClickButton = "messenger_chat_paid_tips_1000_clck_btn",
  MessengerChatPaidTips5000ClickButton = "messenger_chat_paid_tips_5000_clck_btn",
  MessengerChatPaidTips10000ClickButton = "messenger_chat_paid_tips_10000_clck_btn",
  MessengerChatLandingPaidTips200ClickButton = "messenger_chat_landing_paid_tips_200_clck_btn",
  MessengerChatLandingPaidTips500ClickButton = "messenger_chat_landing_paid_tips_500_clck_btn",
  MessengerChatLandingPaidTips1000ClickButton = "messenger_chat_landing_paid_tips_1000_clck_btn",
  MessengerChatLandingPaidTips5000ClickButton = "messenger_chat_landing_paid_tips_5000_clck_btn",
  MessengerChatLandingPaidTips10000ClickButton = "messenger_chat_landing_paid_tips_10000_clck_btn",
  MessengerPaidLandingMysteryBox100ClickButton = "messenger_paid_landing_mysterybox_100_clk_btn",
  MessengerPaidLandingMysteryBox500ClickButton = "messenger_paid_landing_mysterybox_500_clk_btn",
  MessengerPaidLandingMysteryBox5000ClickButton = "messenger_paid_landing_mysterybox_5000_clk_btn",
  MessengerPaidLandingMysteryBox10000ClickButton = "messenger_paid_landing_mysterybox_10000_clk_btn",
  MessengerChatPaidMysteryBox100ClickButton = "messenger_chat_paid_mysterybox_100_clk_btn",
  MessengerChatPaidMysteryBox500ClickButton = "messenger_chat_paid_mysterybox_500_clk_btn",
  MessengerChatPaidMysteryBox5000ClickButton = "messenger_chat_paid_mysterybox_5000_clk_btn",
  MessengerChatPaidMysteryBox10000ClickButton = "messenger_chat_paid_mysterybox_10000_clk_btn",
  MessengerMysteryBoxPopup100Click = "messenger_mysterybox_popup_100_clck",
  MessengerMysteryBoxPopup500Click = "messenger_mysterybox_popup_500_clck",
  MessengerMysteryBoxPopup5000Click = "messenger_mysterybox_popup_5000_clck",
  MessengerMysteryBoxPopup10000Click = "messenger_mysterybox_popup_10000_clck",
  MessengerChatTipsPopupClick = "messenger_chat_tips_popup_clk",
  MessengerChatMysteryBoxPopupClick = "messenger_chat_mysterybox_popup_clk",
  MessengerChatScriptsPopupClick = "messenger_chat_scripts_popup_clk",
  MessengerChatVotesPopupClick = "messenger_chat_votes_popup_clk",
  MessengerChatVotesPopupXSSendClickButton = "messenger_chat_votes_popup_xs_send_clk_btn",
  MessengerChatVotesPopupSSendClickButton = "messenger_chat_votes_popup_s_send_clk_btn",
  MessengerChatVotesPopupMSendClickButton = "messenger_chat_votes_popup_m_send_clk_btn",
  MessengerChatVotesPopupLSendClickButton = "messenger_chat_votes_popup_l_send_clk_btn",
  MessengerChatVotesPopupXLSendClickButton = "messenger_chat_votes_popup_xl_send_clk_btn",
  MessengerChatVotesPopupXXLSendClickButton = "messenger_chat_votes_popup_xxl_send_clk_btn",
  MessengerChatVotesPopupChooseVideoClick = "messenger_chat_votes_popup_choose_video_clk",
  MessengerChatVotesPopupEditClick = "messenger_chat_votes_popup_edit_clk",
  MessengerChatVotesPopupEditCloseClick = "messenger_chat_votes_popup_edit_close_clk",
  MessengerChatVotesVoteForMeXSClickButton = "messenger_chat_votes_vote_for_me_xs_clk_btn",
  MessengerChatVotesVoteForMeSClickButton = "messenger_chat_votes_vote_for_me_s_clk_btn",
  MessengerChatVotesVoteForMeMClickButton = "messenger_chat_votes_vote_for_me_m_clk_btn",
  MessengerChatVotesVoteForMeLClickButton = "messenger_chat_votes_vote_for_me_l_clk_btn",
  MessengerChatVotesVoteForMeXLClickButton = "messenger_chat_votes_vote_for_me_xl_clk_btn",
  MessengerChatVotesVoteForMeXXLClickButton = "messenger_chat_votes_vote_for_me_xxl_clk_btn",
  MessengerLandingVotesVoteForMeXSClickButton = "messenger_landing_votes_vote_for_me_xs_clk_btn",
  MessengerLandingVotesVoteForMeSClickButton = "messenger_landing_votes_vote_for_me_s_clk_btn",
  MessengerLandingVotesVoteForMeMClickButton = "messenger_landing_votes_vote_for_me_m_clk_btn",
  MessengerLandingVotesVoteForMeLClickButton = "messenger_landing_votes_vote_for_me_l_clk_btn",
  MessengerLandingVotesVoteForMeXLClickButton = "messenger_landing_votes_vote_for_me_xl_clk_btn",
  MessengerLandingVotesVoteForMeXXLClickButton = "messenger_landing_votes_vote_for_me_xxl_clk_btn",
  MessengerLandingVotesAvatarClick = "messenger_landing_votes_avatar_clk",
  MessengerPaidVotesLandingChatWithMeClickButton = "messenger_paid_votes_landing_chat_with_me_clck_btn",
  MessengerLandingRealGiftDonateMeSClickButton = "messenger_landing_real_gift_donate_me_s_clk_btn",
  MessengerLandingRealGiftDonateMeMClickButton = "messenger_landing_real_gift_donate_me_m_clk_btn",
  MessengerLandingRealGiftDonateMeLClickButton = "messenger_landing_real_gift_donate_me_l_clk_btn",
  MessengerLandingRealGiftDonateMeXLClickButton = "messenger_landing_real_gift_donate_me_xl_clk_btn",
  MessengerLandingRealGiftDonateMeXXLClickButton = "messenger_landing_real_gift_donate_me_xxl_clk_btn",
  RealGiftLandingShowPage = "real_gift_landing_showpage",
  MessengerChatOnlineGiftGiftMe_SIZE_ClickButton = "messenger_chat_online_gift_gift_me_SIZE_clk_btn",
  MessengerLandingOnlineGiftGiftMe_SIZE_ClickButton = "messenger_landing_online_gift_gift_me_SIZE_clk_btn",
  MessengerLandingOnlineGiftAvatarClick = "messenger_landing_online_gift_avatar_clk",
  OnlineGiftLandingShowpage = "online_gift_landing_showpage",
  MessengerChatLinkbioRealHomeClkBtn = "messenger_chat_linkbio_real_home_clk_btn",
  MessengerChatLinkbioProfessionalSexyClkBtn = "messenger_chat_linkbio_professional_sexy_clk_btn",
  MessengerChatLinkbioFantasySetClkBtn = "messenger_chat_linkbio_fantasy_set_clk_btn",
  MessengerChatLinkbioGetForFreeClkBtn = "messenger_chat_linkbio_get_for_free_clk_btn",
  MessengerChatLinkbioBuyClkBtn = "messenger_chat_linkbio_buy_clk_btn",
  MessengerChatLinkbioUnlockClkBtn = "messenger_chat_linkbio_unlock_clk_btn",
  LogoutRegular = "logout_regular",
  LoginByHashSuccess = "login_by_hash_success",
  MessengerChatWidgetClick = "messenger_chat_widget_clk",
  MessengerChatCustomTipsTipMeSClickButton = "messenger_chat_custom_tips_tip_me_s_clk_btn",
  MessengerChatCustomTipsTipMeMClickButton = "messenger_chat_custom_tips_tip_me_m_clk_btn",
  MessengerChatCustomTipsTipMeLClickButton = "messenger_chat_custom_tips_tip_me_l_clk_btn",
  MessengerChatCustomTipsTipMeXLClickButton = "messenger_chat_custom_tips_tip_me_xl_clk_btn",
  MessengerChatCustomTipsTipMeXXLClickButton = "messenger_chat_custom_tips_tip_me_xxl_clk_btn",
  MessengerChatCustomTipsUnpackClickButton = "messenger_chat_custom_tips_unpack_clk_btn",
  MessengerChatCustomTipsUnpackShow = "messenger_chat_custom_tips_unpack_show",
  MessengerChatCustomTipsUnpackMediaShow = "messenger_chat_custom_tips_unpack_media_show",
  MessengerLandingCustomTipsTipMeSClickButton = "messenger_landing_custom_tips_tip_me_s_clk_btn",
  MessengerLandingCustomTipsTipMeMClickButton = "messenger_landing_custom_tips_tip_me_m_clk_btn",
  MessengerLandingCustomTipsTipMeLClickButton = "messenger_landing_custom_tips_tip_me_l_clk_btn",
  MessengerLandingCustomTipsTipMeXLClickButton = "messenger_landing_custom_tips_tip_me_xl_clk_btn",
  MessengerLandingCustomTipsTipMeXXLClickButton = "messenger_landing_custom_tips_tip_me_xxl_clk_btn",
  MessengerLandingCustomTipsChatWithMeClickButton = "messenger_landing_custom_tips_chat_with_me_clk_btn",
  MessengerLandingCustomTipsAvatarClick = "messenger_landing_custom_tips_avatar_clk",
  MessengerChatConnectionError = "messenger_chat_connection_error",
}

export interface IVideoViewedOptions {
  challengeName?: string;
  challengeUid?: string;
  videoUid?: string;
  model?: string;
  modelUid?: string;
  isOnline?: boolean;
  duration?: number;
  rating?: number;
  source?: AnalyticSource;
  viewTime?: string;
}

export interface IPaymentOfferViewedOptions {
  billing: PaymentProvider;
  originalCurrency?: string;
  originalPrice?: number;
  isOnline?: boolean;
  model?: string;
  modelUid?: string;
  audioCount?: number;
  imageCount?: number;
  videoCount?: number;
  votesCount?: number;
  title?: string;
  description?: string;
  price?: number;
  productUid?: string;
  type: AnalyticProductType;
  subtype?: string;
  source: AnalyticSource;
  videoUid?: string;
  isExclusive?: boolean;
}
