import {useEffect} from "react";
import {useAppDispatch, useAppSelector} from "@app/store/hooks";
import {profileSelector, setIsAuthenticated} from "@app/store/slices/profile";
import {LocalStorageKey} from "@appTypes/localstorage";

const useAuth = () => {
  const {isAuthenticated} = useAppSelector(profileSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = localStorage.getItem(LocalStorageKey.Token);
    dispatch(setIsAuthenticated(!!token));
  }, []);

  return isAuthenticated || !!localStorage.getItem(LocalStorageKey.Token);
};

export default useAuth;
