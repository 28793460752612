import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  mystery: undefined,
  visible: false,
};

export const mysteryEditOverlaySlice = createSlice({
  name: "mysteryEditOverlay",
  initialState,
  reducers: {
    setMysteryEdit(state, action) {
      state.mystery = action.payload.mystery;
    },
    setOpenMysteryEditOverlay(state) {
      state.visible = true;
    },
    setCloseMysteryEditOverlay(state) {
      state.visible = false;
    },
    clearMysteryEditOverlay() {
      return initialState;
    },
  },
});

export const {
  setMysteryEdit,
  setOpenMysteryEditOverlay,
  setCloseMysteryEditOverlay,
  clearMysteryEditOverlay,
} = mysteryEditOverlaySlice.actions;

export const mysteryEditOverlaySelector = (state) => state.mysteryEditOverlay;

export const mysteryEditOverlayReducer = mysteryEditOverlaySlice.reducer;
