/* eslint-disable no-restricted-globals */
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  const measureDownloadDuration = async () => {
    const imageUrl = `${process.env.REACT_APP_CDN_URL}/og-image-logo.jpg`;

    try {
      const startTime = Date.now();
      const response = await fetch(imageUrl, {
        cache: "no-cache",
      });
      const endTime = Date.now();

      return endTime - startTime;
    } catch (error) {
      console.error("Error measuring internet speed:", error);
      return 0;
    }
  };

  self.addEventListener("message", (event) => {
    if (event.data && event.data.type === "START_SPEED_TEST") {
      setInterval(() => {
        measureDownloadDuration().then((duration) => {
          self.postMessage({type: "SPEED_TEST_RESULT", duration});
        });
      }, 60000);
    }
  });
};
