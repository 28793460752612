import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  items: [],
  currentPage: 1,
  activeSlide: 0,
  lastPage: false,
  forceUpdate: false,
  loading: true,
  error: undefined,
};

export const contentFeedSlice = createSlice({
  name: "contentFeed",
  initialState,
  reducers: {
    setContentFeedActiveSlide(state, action) {
      state.activeSlide = action.payload;
    },
    setContentFeedDelete(state, action) {
      state.items = state.items.filter((item) => item.uid !== action.payload);
    },
    setContentFeedStatus(state, action) {
      const content = state.items.find(
        (item) => item.uid === action.payload.videoId,
      );
      if (content) {
        content.status = action.payload.status;
      }
    },
    setContentFeedItems(state, action) {
      state.items = action.payload;
    },
    updateVoteCountContentFeed: (state, action) => {
      const {vote, contentId} = action.payload;
      const currentContent = state.items.find((item) => item.uid === contentId);

      if (currentContent) {
        if (vote) {
          currentContent.voteCount = currentContent.voteCount + vote;
        }
        currentContent.prices.freeVoteDate = {
          ...currentContent.prices.freeVoteDate,
          date: action.payload.date ?? currentContent.prices.freeVoteDate,
        };
      }
    },
    clearContentFeed() {
      return initialState;
    },
  },
});

export const {
  setContentFeedActiveSlide,
  setContentFeedDelete,
  setContentFeedStatus,
  setContentFeedItems,
  updateVoteCountContentFeed,
  clearContentFeed,
} = contentFeedSlice.actions;

export const contentFeedSelector = (state) => state.contentFeed;

export const contentFeedReducer = contentFeedSlice.reducer;
