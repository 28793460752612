import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  items: [],
  pagesCount: [],
  currentPage: 1,
  activeSlide: 0,
  lastPage: false,
  forceUpdate: false,
  muted: true,
  loading: true,
  error: undefined,
};

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setActiveSlide: (state, action) => {
      state.activeSlide = action.payload;
    },
    setLoading: (state) => {
      state.loading = true;
    },
    toggleFeedMuted: (state, action) => {
      state.muted = action.payload ? action.payload : !state.muted;
    },
    setLoadingSuccess: (state, action) => {
      const loadPage = state.currentPage !== action.payload.nextPage;
      state.loading = false;

      if (!loadPage || state.forceUpdate) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }

      //state.lastPage = action.payload.count < 5;
      state.currentPage = action.payload.nextPage;
    },
    setLoadingError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateVoteCountFeed: (state, action) => {
      const {vote, contentId} = action.payload;
      const currentContent = state.items.find((item) => item.uid === contentId);

      if (currentContent) {
        if (vote) {
          currentContent.voteCount = currentContent.voteCount + vote;
        }
        currentContent.prices.freeVoteDate = {
          ...currentContent.prices.freeVoteDate,
          date: action.payload.date ?? currentContent.prices.freeVoteDate.date,
        };
      }
    },
    clearFeed: () => {
      return initialState;
    },
  },
});

export const {
  setActiveSlide,
  setLoading,
  setLoadingSuccess,
  setLoadingError,
  toggleFeedMuted,
  updateVoteCountFeed,
  clearFeed,
} = feedSlice.actions;

export const feedSelector = (state) => state.feed;

export const feedReducer = feedSlice.reducer;
