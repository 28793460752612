import {createSlice} from "@reduxjs/toolkit";
import {PayloadAction} from "@reduxjs/toolkit";

const initialState = {
  challenge: undefined,
  topItems: [],
  newItems: [],
  topCurrentPage: 1,
  newCurrentPage: 1,
  topLastPage: false,
  newLastPage: false,
  userHasVideo: false,
  loading: false, // TODO: fixed
  error: undefined,
};

export const challengeSlice = createSlice({
  name: "challenge",
  initialState,
  reducers: {
    setChallenge(state, action) {
      state.loading = false;
      state.challenge = action.payload;
    },
    setChallengeParticipants(state, action) {
      state.challenge = {
        ...state.challenge,
        participantsCount:
          state.challenge.participantsCount +
          (action.payload === "join" ? 1 : -1),
      };
    },
    setChallengeContentDelete(state, action) {
      state.topItems = state.topItems.filter(
        (item) => item.uid !== action.payload,
      );
      state.newItems = state.newItems.filter(
        (item) => item.uid !== action.payload,
      );
    },
    setChallengeContentStatus(state, action) {
      const contentInTop = state.topItems.find(
        (item) => item.uid === action.payload.videoId,
      );
      const contentInNew = state.newItems.find(
        (item) => item.uid === action.payload.videoId,
      );
      if (contentInTop) {
        contentInTop.status = action.payload.status;
      }
      if (contentInNew) {
        contentInNew.status = action.payload.status;
      }
    },
    setChallengeUserContent(state, action) {
      state.topItems = [...[action.payload], ...state.topItems];
      state.newItems = [...[action.payload], ...state.newItems];
    },
    setChallengeTopItems(state, action) {
      const items = Array.isArray(action.payload.items)
        ? action.payload.items
        : [];
      const loadPage = state.topCurrentPage !== action.payload.nextPage;
      state.loading = false;

      if (!loadPage || state.forceUpdate) {
        state.topItems = items;
      } else {
        state.topItems = [...state.topItems, ...items];
      }

      state.topLastPage = action.payload.count === 0;
      state.topCurrentPage = action.payload.nextPage;
    },
    setChallengeNewItems(state, action) {
      const items = Array.isArray(action.payload.items)
        ? action.payload.items
        : [];
      const loadPage = state.newCurrentPage !== action.payload.nextPage;
      state.loading = false;

      if (!loadPage || state.forceUpdate) {
        state.newItems = items;
      } else {
        state.newItems = [...state.newItems, ...items];
      }

      state.newLastPage = action.payload.count === 0;
      state.newCurrentPage = action.payload.nextPage;
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setLoadingSuccess(state, action) {
      state.loading = false;
      state.challenge = action.payload;
    },
    setLoadingError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateVoteCountChallengeContents: (state, action) => {
      const {vote, contentId} = action.payload;
      const currentTopContent = state.topItems.find(
        (item) => item.uid === contentId,
      );
      const currentNewContent = state.newItems.find(
        (item) => item.uid === contentId,
      );

      if (currentTopContent) {
        if (vote) {
          currentTopContent.voteCount = currentTopContent.voteCount + vote;
        }
        currentTopContent.prices.freeVoteDate = {
          ...currentTopContent.prices.freeVoteDate,
          date:
            action.payload.date ?? currentTopContent.prices.freeVoteDate.date,
        };
      }

      if (currentNewContent) {
        currentNewContent.voteCount = currentNewContent.voteCount + vote;
        currentNewContent.prices.freeVoteDate = {
          ...currentNewContent.prices.freeVoteDate,
          date: action.payload.date,
        };
      }
    },
    updateChallengeDescription(state, action) {
      const topItem = state.topItems.find(
        (item) => item.uid === action.payload.contentId,
      );
      if (topItem) {
        topItem.description = action.payload.description;
      }
      const newItem = state.newItems.find(
        (item) => item.uid === action.payload.contentId,
      );
      if (newItem) {
        newItem.description = action.payload.description;
      }
    },
    clearChallenge() {
      return initialState;
    },
  },
});

export const {
  setChallenge,
  setChallengeUserContent,
  updateVoteCountChallengeContents,
  setChallengeParticipants,
  setChallengeContentDelete,
  setChallengeContentStatus,
  setChallengeTopItems,
  setChallengeNewItems,
  setLoading,
  setLoadingSuccess,
  setLoadingError,
  updateChallengeDescription,
  clearChallenge,
} = challengeSlice.actions;

export const challengeSelector = (state) => state.challenge;

export const challengeReducer = challengeSlice.reducer;
