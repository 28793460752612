import React, {useCallback, useEffect} from "react";
import speedTestWorker from "../speed-test-sw";
import {createWorker} from "../utils/createWorker";
import {useAppDispatch, useAppSelector} from "../app/store/hooks";
import {configSelector, partialUpdateConfig} from "../app/store/slices/config";
import {NetworkSpeedType} from "../app/store/interfaces/config";

const useSpeedTest = () => {
  const {slowNetworkNotificationViewed} = useAppSelector(configSelector);
  const dispatch = useAppDispatch();

  const networkSpeedTypeByDuration = (duration: number) => {
    if (duration < 500) {
      return NetworkSpeedType.Fast;
    } else if (duration >= 500 && duration < 1500) {
      return NetworkSpeedType.Normal;
    } else {
      return NetworkSpeedType.Slow;
    }
  };

  const updateNetwork = (event: MessageEvent) => {
    const {type, duration} = event.data;
    if (type === "SPEED_TEST_RESULT") {
      const networkSpeedType = networkSpeedTypeByDuration(duration);

      dispatch(
        partialUpdateConfig({
          networkType: networkSpeedType,
          networkDuration: duration,
        }),
      );
    }
  };

  useEffect(() => {
    const worker = createWorker(speedTestWorker);

    worker.postMessage({
      type: "START_SPEED_TEST",
    });

    worker.addEventListener("message", updateNetwork);
  }, []);
};

export default useSpeedTest;
