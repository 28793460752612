export enum FormType {
  Signin = "Signin",
  Signup = "Signup",
}

export enum FormState {
  AgeConfirmation = "AgeConfirmation",
  CountrySelection = "CountrySelection",
  Auth = "Auth",
}

export enum WithoutAuthFormType {
  None = "None",
  Default = "Default",
  VoteFree = "VoteFree",
  Like = "Like",
  Comment = "Comment",
  Join = "Join",
  Subscribe = "Subscribe",
  Follow = "Follow",
  VideoPlayer = "VideoPlayer",
  GetForFreeHotContent = "GetForFreeHotContent",
}

export enum WithoutAuthRegPoint {
  Chat = "chat",
  FollowButton = "follow_btn",
  VoteButton = "vote_btn",
  LikeButton = "like_btn",
  CommentButton = "comment_btn",
  RedAlertPopup = "red_alert_popup",
  JoinChallengeButton = "join_challenge_btn",
  SubscribeChallengeButton = "subscribe_challenge_btn",
  VideoPlayerTimeout = "video_player_timeout",
  GetForFreeHotContent = "get_for_free_hot_content",
  ProfileSettings = "profile_settings",
  ShareProfile = "share_profile",
  CreateAccountBtn = "create_account_btn",
  CreateMysteryBox = "create_mystery_box",
  OpenFollowersOrViewers = "open_followers_or_viewers",
  ShowcaseProfile = "showcase_profile",
}

export enum WithoutAuthRegPlace {
  Feed = "Feed",
  Profile = "Profile",
  Challenge = "Challenge",
}
