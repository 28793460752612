export const getUniqueArray = (
  arrayWithDuplicates: Array<any>,
  uniqueBy: string,
) => {
  return arrayWithDuplicates.reduce((acc, current) => {
    const x = acc.find((item: any) => item[uniqueBy] === current[uniqueBy]);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);
};
