import {createSlice} from "@reduxjs/toolkit";
import {AppState} from "../store";
import {INotification} from "../interfaces/notification";

interface INotificationsState {
  items: INotification[];
  currentPage: number;
  lastPage: boolean;
  loading: boolean;
  error?: string;
}

const initialState: INotificationsState = {
  items: [],
  currentPage: 1,
  lastPage: false,
  loading: true,
  error: undefined,
};

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotificationsLoading: (state) => {
      state.loading = true;
    },
    setNotificationsLoadingSuccess: (state, action) => {
      const loadPage = state.currentPage !== action.payload.nextPage;
      state.loading = false;

      if (!loadPage) {
        state.items = action.payload.items;
      } else {
        state.items = [...state.items, ...action.payload.items];
      }

      state.lastPage = action.payload.count < 20;
      state.currentPage = action.payload.nextPage;
    },
    setNotificationsLoadingError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearNotification: () => {
      return initialState;
    },
  },
});

export const {
  setNotificationsLoading,
  setNotificationsLoadingSuccess,
  setNotificationsLoadingError,
  clearNotification,
} = notificationsSlice.actions;

export const notificationsSelector = (state: AppState) => state.notifications;

export const notificationsReducer = notificationsSlice.reducer;
