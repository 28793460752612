import {useEffect} from "react";
import {checkFirebasePermissionAndUpdateToken} from "../utils/pushUtils";
import {useAppSelector} from "../app/store/hooks";
import {
  currentProfileSelector,
  profileSelector,
} from "../app/store/slices/profile";

const useFirebaseTokenUpdater = () => {
  const {isAuthenticated} = useAppSelector(profileSelector);
  const {isAnonym} = useAppSelector(currentProfileSelector);
  useEffect(() => {
    if (isAuthenticated && !isAnonym) {
      checkFirebasePermissionAndUpdateToken(isAnonym);
    }
  }, [isAuthenticated]);
};

export default useFirebaseTokenUpdater;
