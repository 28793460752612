import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  status: "active",

  activeItems: [],
  activeLastPage: false,
  activeCurrentPage: 1,
  activeLoading: true,

  pastItems: [],
  pastLastPage: false,
  pastCurrentPage: 1,
  pastLoading: true,

  error: undefined,
};

export const challengesSlice = createSlice({
  name: "challenges",
  initialState,
  reducers: {
    setChallengesStatus: (state, action) => {
      state.status = action.payload;
    },
    setLoading: (state) => {
      if (state.status === "active") {
        state.activeLoading = true;
      } else {
        state.pastLoading = true;
      }
    },
    setLoadingSuccess: (state, action) => {
      const isActiveStatus = action.payload.status === "active";
      let loadPage;

      if (isActiveStatus) {
        state.activeLoading = false;
        loadPage =
          state.activeCurrentPage !== action.payload.nextPage &&
          action.payload.nextPage !== 1;
      } else {
        state.pastLoading = false;
        loadPage =
          state.pastCurrentPage !== action.payload.nextPage &&
          action.payload.nextPage !== 1;
      }

      if (!loadPage) {
        if (isActiveStatus) {
          state.activeItems = action.payload.items;
        } else {
          state.pastItems = action.payload.items;
        }
      } else {
        if (isActiveStatus) {
          state.activeItems = [...state.activeItems, ...action.payload.items];
        } else {
          state.pastItems = [...state.pastItems, ...action.payload.items];
        }
      }

      if (isActiveStatus) {
        state.activeLastPage = action.payload.count < 15;
        state.activeCurrentPage = action.payload.nextPage;
      } else {
        state.pastLastPage = action.payload.count < 15;
        state.pastCurrentPage = action.payload.nextPage;
      }
    },
    setLoadingError: (state, action) => {
      if (state.status === "active") {
        state.activeLoading = false;
      } else {
        state.pastLoading = false;
      }
      state.error = action.payload;
    },
    clearChallenges() {
      return initialState;
    },
  },
});

export const {
  setChallengesStatus,
  setLoading,
  setLoadingSuccess,
  setLoadingError,
  clearChallenges,
} = challengesSlice.actions;

export const challengesSelector = (state) => state.challenges;

export const challengesReducer = challengesSlice.reducer;
