import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  type: "edit",
  contentId: undefined,
  visible: false,
};

export const contentOverlaySlice = createSlice({
  name: "contentOverlay",
  initialState,
  reducers: {
    setContent(state, action) {
      state.type = action.payload.type;
      state.contentId = action.payload.contentId;
    },
    setOpen(state) {
      state.visible = true;
    },
    setClose(state) {
      state.visible = false;
    },
    clearContentOverlay() {
      return initialState;
    },
  },
});

export const {setContent, setOpen, setClose, clearContentOverlay} =
  contentOverlaySlice.actions;

export const contentOverlaySelector = (state) => state.contentOverlay;

export const contentOverlayReducer = contentOverlaySlice.reducer;
