import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IFollow, IViewer} from "../../../pages/followers/type";
import {AppState} from "../store";
import {getUniqueArray} from "../../../utils/array";

interface IUpdateFollowUser {
  segment: "current" | "selected";
  list: "followersList" | "followingList";
  uid: string;
}

interface IFollowersState {
  currentProfile: {
    followersList: IFollow[];
    followingList: IFollow[];
    viewers: IViewer[];
  };
  selectedProfile: {
    followersList: IFollow[];
    followingList: IFollow[];
    viewers: IViewer[];
  };
}

const initialState: IFollowersState = {
  currentProfile: {
    followersList: [],
    followingList: [],
    viewers: [],
  },
  selectedProfile: {
    followersList: [],
    followingList: [],
    viewers: [],
  },
};

export const followersSlice = createSlice({
  name: "followers",
  initialState,
  reducers: {
    setFollowersList: (
      state,
      action: PayloadAction<{
        segment: "current" | "selected";
        items: IFollow[];
      }>,
    ) => {
      const {segment, items} = action.payload;

      if (segment === "current") {
        state.currentProfile.followersList = getUniqueArray(
          [...state.currentProfile.followersList, ...items],
          "uid",
        );
      }

      if (segment === "selected") {
        state.selectedProfile.followersList = getUniqueArray(
          [...state.selectedProfile.followersList, ...items],
          "uid",
        );
      }
    },
    setFollowingList: (
      state,
      action: PayloadAction<{
        segment: "current" | "selected";
        items: IFollow[];
      }>,
    ) => {
      const {segment, items} = action.payload;

      if (segment === "current") {
        state.currentProfile.followingList = getUniqueArray(
          [...state.currentProfile.followingList, ...items],
          "uid",
        );
      }

      if (segment === "selected") {
        state.selectedProfile.followingList = getUniqueArray(
          [...state.selectedProfile.followingList, ...items],
          "uid",
        );
      }
    },
    setViewersList: (
      state,
      action: PayloadAction<{
        segment: "current" | "selected";
        items: IViewer[];
      }>,
    ) => {
      const {segment, items} = action.payload;

      if (segment === "current") {
        state.currentProfile.viewers = [
          ...state.currentProfile.viewers,
          ...items,
        ];
      }

      if (segment === "selected") {
        state.selectedProfile.viewers = [
          ...state.selectedProfile.viewers,
          ...items,
        ];
      }
    },
    updateFollowUser: (state, action: PayloadAction<IUpdateFollowUser>) => {
      const {segment, uid, list} = action.payload;

      if (segment === "current" && list === "followersList") {
        state.currentProfile.followersList =
          state.currentProfile.followersList.map((item) => {
            if (uid === item.uid) {
              return {
                ...item,
                followingStatus:
                  item.followingStatus === "i_follow_user"
                    ? "i_unfollow_user"
                    : "i_follow_user",
              };
            }
            return item;
          });
      }

      if (segment === "current" && list === "followingList") {
        state.currentProfile.followingList =
          state.currentProfile.followingList.map((item) => {
            if (uid === item.uid) {
              return {
                ...item,
                followingStatus:
                  item.followingStatus === "i_follow_user"
                    ? "i_unfollow_user"
                    : "i_follow_user",
              };
            }
            return item;
          });
      }

      if (segment === "selected" && list === "followersList") {
        state.selectedProfile.followersList =
          state.selectedProfile.followersList.map((item) => {
            if (uid === item.uid) {
              return {
                ...item,
                followingStatus:
                  item.followingStatus === "i_follow_user"
                    ? "i_unfollow_user"
                    : "i_follow_user",
              };
            }
            return item;
          });
      }

      if (segment === "selected" && list === "followingList") {
        state.selectedProfile.followingList =
          state.selectedProfile.followingList.map((item) => {
            if (uid === item.uid) {
              return {
                ...item,
                followingStatus:
                  item.followingStatus === "i_follow_user"
                    ? "i_unfollow_user"
                    : "i_follow_user",
              };
            }
            return item;
          });
      }
    },
    removeFromFollowers: (state, action: PayloadAction<{uid: string}>) => {
      state.currentProfile.followersList =
        state.currentProfile.followersList.filter((followerItem) => {
          return followerItem.uid !== action.payload.uid;
        });
    },
    cleanCollections: (state) => {
      state.currentProfile.followingList = [];
      state.currentProfile.followersList = [];
      state.currentProfile.viewers = [];
      state.selectedProfile.followingList = [];
      state.selectedProfile.followersList = [];
      state.selectedProfile.viewers = [];
    },
  },
});

export const {
  setFollowingList,
  setFollowersList,
  updateFollowUser,
  cleanCollections,
  removeFromFollowers,
  setViewersList,
} = followersSlice.actions;

export const followersSelector = (state: AppState) => state.followers;
export const currentFollowersSelector = (state: AppState) =>
  state.followers.currentProfile;
export const selectedFollowersSelector = (state: AppState) =>
  state.followers.selectedProfile;

export const followersReducer = followersSlice.reducer;
