import {createContext, useContext} from "react";
import {StreamChat} from "stream-chat";

export const StreamChatContext = createContext<StreamChat | null>(null);

export const useStreamChat = () => {
  const chatClient = useContext(StreamChatContext);
  if (!chatClient) {
    return undefined;
  }
  return chatClient;
};
