import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppState} from "../store";
import {CountryItem} from "@components/country-autocomplete/type";

interface ISignupStore {
  country?: CountryItem | undefined;
  reffererUserUid?: string;
  reffererChallengeId?: string;
}

const initialState: ISignupStore = {};

export const signupSlice = createSlice({
  name: "signup",
  initialState,
  reducers: {
    setReffererUserUid: (state, action: PayloadAction<string | undefined>) => {
      state.reffererUserUid = action.payload;
    },
    setReffererChallengeId: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.reffererChallengeId = action.payload;
    },
    setCountry: (state, action: PayloadAction<CountryItem | undefined>) => {
      state.country = action.payload;
    },
  },
});

export const {setReffererUserUid, setReffererChallengeId, setCountry} =
  signupSlice.actions;

export const signupSelector = (state: AppState) => state.signup;

export const signupReducer = signupSlice.reducer;
