import {LocalStorageKey} from "@appTypes/localstorage";

const clearStorageUserData = () => {
  localStorage.removeItem(LocalStorageKey.Token);
  localStorage.removeItem(LocalStorageKey.RefreshToken);
  localStorage.removeItem(LocalStorageKey.StreamToken);
  localStorage.removeItem(LocalStorageKey.RegistrationEmail);
  localStorage.removeItem(LocalStorageKey.UserId);
  localStorage.removeItem(LocalStorageKey.Login);
  localStorage.removeItem(LocalStorageKey.Register);
  localStorage.removeItem(LocalStorageKey.UserCountry);
  localStorage.removeItem(LocalStorageKey.AnonUserId);
  localStorage.removeItem(LocalStorageKey.FreeVotesCount);
  localStorage.removeItem(LocalStorageKey.AnonPaymentPopup);
  localStorage.removeItem(LocalStorageKey.AfterAnonAuthPage);
  localStorage.removeItem(LocalStorageKey.AfterHashAuthPage);
  window.location.assign(window.location.origin);
};

export default clearStorageUserData;
