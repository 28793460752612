import React from "react";
import styles from "../notification.module.scss";
import {useNavigate} from "react-router-dom";
import {PROFILE_CURRENT} from "../../../app/router.constants";

const LogoutFailed = () => {
  const navigate = useNavigate();
  const handleMoreClick = () => {
    navigate(PROFILE_CURRENT);
  };
  return (
    <span>
      Failed to logout. Please try again. If the problem persists, please
      contact support.&nbsp;
      <br />
    </span>
  );
};

export default LogoutFailed;
