import toast from "react-hot-toast";

const useNotification = () => {
  const send = (params) => {
    const {type, text, options} = params;

    const toastStyles = {
      color: "#fff",
      fontSize: "12px",
      padding: "14px 60px 14px",
      width: "calc(100% - 28px)",
      maxWidth: "100%",
      margin: "14px auto",
      borderRadius: "8px",
      lineHeight: "20px",
      overflow: "hidden",
    };

    toast.remove();
    if (type === "message") {
      toast.success(text, {
        ...options,
        style: {
          ...toastStyles,
          background: "rgb(21 21 21 / 90%)",
        },
      });
    }
    if (type === "warning") {
      toast.success(text, {
        ...options,
        style: {
          ...toastStyles,
          background: "rgb(245 128 0 / 90%)",
        },
      });
    }
    if (type === "error") {
      toast.error(text, {
        ...options,
        style: {
          ...toastStyles,
          background: "rgb(238 45 45 / 90%)",
        },
      });
    }
  };

  const clear = () => {
    toast.remove();
  };

  return {
    send,
    clear,
  };
};

export default useNotification;
