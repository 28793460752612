import React, {useState} from "react";
import styles from "../notification.module.scss";

const SlowNetwork = () => {
  const [readMoreVisible, setReadMoreVisible] = useState(true);

  const handleMoreClick = () => {
    setReadMoreVisible(false);
  };
  return (
    <span>
      Your internet connection is unstable.&nbsp;
      {readMoreVisible && (
        <>
          <br />
          <span onClick={handleMoreClick} className={styles.link}>
            Tap to read more
          </span>
        </>
      )}
      {!readMoreVisible && (
        <>Switch to mobile internet or find a reliable Wi-Fi hotspot</>
      )}
    </span>
  );
};

export default SlowNetwork;
