import {createBrowserRouter, Navigate} from "react-router-dom";
import {
  CHALLENGE_ITEM,
  CHALLENGE_ITEM_RULES,
  CHALLENGE_LIST,
  COMMUNITY_GUIDELINES,
  CONTENT,
  COOKIES_POLICY,
  CUSTOM_TIPS_LANDING,
  FB_DATA_DELETION,
  INFORMATION,
  MESSAGES,
  MYSTERY_BOX,
  MYSTERY_BOXES_LANDING,
  NOTIFICATION,
  ONLINE_GIFTS_LANDING,
  PAGE_NOT_FOUND,
  PAYMENT_FAILED,
  PAYMENT_RU,
  PAYMENT_SUCCESS,
  PAYMENTS,
  PRIVACY_POLICY,
  PRIVACY_POLICY_RU,
  PROFILE_CURRENT,
  PROFILE_CURRENT_FOLLOWERS,
  PROFILE_ITEM,
  PROFILE_ITEM_FOLLOWERS,
  REAL_GIFTS_LANDING,
  REQUISITES_RU,
  ROOT,
  SETTINGS,
  TERMS_OF_SERVICE,
  TERMS_OF_SERVICE_RU,
  TIPS_LANDING,
  USER_AUTH,
  USER_CONFIRMATION_FAILED,
  USER_LANDING,
  USER_LANDING_BY_ACTION,
  USER_LANDING_BY_CONTENT,
  USER_NEW_PASSWORD,
  USER_RESET_PASSWORD,
  USER_RESET_PASSWORD_FAILED,
  VOTES_LANDING,
} from "./router.constants";
import {lazy} from "react";
import componentLoader from "@app/componentLoader";
import PaymentResolver from "@pages/payments/payou/paymentResolver";

const ATTEMPTS_LEFT = 2;

const Feed = lazy(() =>
  componentLoader(() => import("../pages/feed/feed"), ATTEMPTS_LEFT),
);
const Challenges = lazy(() =>
  componentLoader(
    () => import("../pages/challenges/challenges"),
    ATTEMPTS_LEFT,
  ),
);
const ChallengeItem = lazy(() =>
  componentLoader(
    () => import("../pages/challenge-item/challengeItem"),
    ATTEMPTS_LEFT,
  ),
);
const Notifications = lazy(() =>
  componentLoader(
    () => import("../pages/notification/notification"),
    ATTEMPTS_LEFT,
  ),
);

const SelectedProfile = lazy(() =>
  componentLoader(
    () => import("../pages/profile/selectedProfile"),
    ATTEMPTS_LEFT,
  ),
);
const CurrentProfile = lazy(() =>
  componentLoader(
    () => import("../pages/profile/currentProfile"),
    ATTEMPTS_LEFT,
  ),
);

const ChallengeRules = lazy(() =>
  componentLoader(
    () => import("../pages/challenge-rules/challengeRules"),
    ATTEMPTS_LEFT,
  ),
);
const ResetPassword = lazy(() =>
  componentLoader(() => import("../pages/auth/resetPassword"), ATTEMPTS_LEFT),
);
const Content = lazy(() =>
  componentLoader(() => import("../pages/content/content"), ATTEMPTS_LEFT),
);
const Settings = lazy(() =>
  componentLoader(() => import("../pages/settings/settings"), ATTEMPTS_LEFT),
);
const Messages = lazy(() =>
  componentLoader(() => import("../pages/messages/messages"), ATTEMPTS_LEFT),
);

const AuthHash = lazy(() => import("../pages/auth/authHash"));
const NewPassword = lazy(() => import("../pages/auth/newPassword"));
const ConfirmationFailed = lazy(
  () => import("../pages/auth/confirmationFailed"),
);
const Payments = lazy(() => import("../pages/payments/payments"));
const Information = lazy(() => import("../pages/information/information"));
const UserLanding = lazy(() => import("../pages/user-landing/v3/userLanding"));
const TipsLanding = lazy(() => import("../pages/tips-landing/tipsLanding"));
const CustomTipsLanding = lazy(
  () => import("../pages/custom-tips-landing/customTipsLanding"),
);
const MysteryBoxesLanding = lazy(
  () => import("../pages/mystery-boxes-landing/mysteryBoxesLanding"),
);
const RealGiftsLanding = lazy(
  () => import("../pages/real-gifts-landing/realGiftsLanding"),
);
const OnlineGiftsLanding = lazy(
  () => import("../pages/online-gifts-landing/onlineGiftsLanding"),
);
const MysteryBox = lazy(() => import("../pages/mystery-box/mysteryBox"));
const IosKeyboardTest = lazy(() => import("../pages/tech/iosKeyboardTest"));
const NotFound = lazy(() => import("../pages/not-found/notFound"));
const InformationArticle = lazy(
  () => import("../pages/information/informationArticle"),
);
const RequisitesRU = lazy(() => import("../pages/information/requisitesRU"));
const PaymentRU = lazy(() => import("../pages/information/paymentRU"));
const FollowersCurrentProfile = lazy(
  () => import("../pages/followers/followersCurrentProfile"),
);
const FollowersSelectedProfile = lazy(
  () => import("../pages/followers/followersSelectedProfile"),
);
const VotesLanding = lazy(() => import("@pages/votes-landing/votesLanding"));

const technicalRoutes = [
  {
    path: "/tech/ios_keyboard",
    element: <IosKeyboardTest />,
  },
];

const router = createBrowserRouter([
  {
    path: ROOT,
    element: <Feed />,
  },
  {
    path: CHALLENGE_LIST,
    element: <Challenges />,
  },
  {
    path: CHALLENGE_ITEM,
    element: <ChallengeItem />,
  },
  {
    path: CHALLENGE_ITEM_RULES,
    element: <ChallengeRules />,
  },
  {
    path: MESSAGES,
    element: <Messages />,
  },
  {
    path: PROFILE_CURRENT,
    element: <CurrentProfile />,
  },
  {
    path: PROFILE_ITEM,
    element: <SelectedProfile />,
  },
  {
    path: PROFILE_CURRENT_FOLLOWERS,
    element: <FollowersCurrentProfile />,
  },
  {
    path: SETTINGS,
    element: <Settings />,
  },
  {
    path: PAYMENTS,
    element: <Payments />,
  },

  {
    path: PROFILE_ITEM_FOLLOWERS,
    element: <FollowersSelectedProfile />,
  },
  {
    path: CONTENT,
    element: <Content />,
  },
  {
    path: MYSTERY_BOX,
    element: <MysteryBox />,
  },
  {
    path: NOTIFICATION,
    element: <Notifications />,
  },
  {
    path: INFORMATION,
    element: <Information />,
  },
  {
    path: TERMS_OF_SERVICE,
    element: <InformationArticle alias="terms_of_service_en" />,
  },
  {
    path: PRIVACY_POLICY,
    element: <InformationArticle alias="privacy_policy_en" />,
  },
  {
    path: COMMUNITY_GUIDELINES,
    element: <InformationArticle alias="community_guidelines_en" />,
  },
  {
    path: COOKIES_POLICY,
    element: <InformationArticle alias="cookies_policy_en" />,
  },
  {
    path: FB_DATA_DELETION,
    element: <InformationArticle alias="fb_data_deletion" />,
  },
  {
    path: TERMS_OF_SERVICE_RU,
    element: <InformationArticle alias="terms_of_service_ru" />,
  },
  {
    path: PRIVACY_POLICY_RU,
    element: <InformationArticle alias="privacy_policy_ru" />,
  },
  {
    path: REQUISITES_RU,
    element: <RequisitesRU />,
  },
  {
    path: PAYMENT_RU,
    element: <PaymentRU />,
  },
  {
    path: USER_RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    path: USER_RESET_PASSWORD_FAILED,
    element: <ResetPassword />,
  },
  {
    path: USER_NEW_PASSWORD,
    element: <NewPassword />,
  },
  {
    path: USER_CONFIRMATION_FAILED,
    element: <ConfirmationFailed />,
  },
  {
    path: USER_AUTH,
    element: <AuthHash />,
  },
  {
    path: USER_LANDING,
    element: <UserLanding />,
  },
  {
    path: USER_LANDING_BY_ACTION,
    element: <UserLanding />,
  },
  {
    path: TIPS_LANDING,
    element: <TipsLanding />,
  },
  {
    path: CUSTOM_TIPS_LANDING,
    element: <CustomTipsLanding />,
  },
  {
    path: VOTES_LANDING,
    element: <VotesLanding />,
  },
  {
    path: MYSTERY_BOXES_LANDING,
    element: <MysteryBoxesLanding />,
  },
  {
    path: REAL_GIFTS_LANDING,
    element: <RealGiftsLanding />,
  },
  {
    path: ONLINE_GIFTS_LANDING,
    element: <OnlineGiftsLanding />,
  },
  {
    path: USER_LANDING_BY_CONTENT,
    element: <UserLanding />,
  },
  {
    path: PAGE_NOT_FOUND,
    element: <NotFound />,
  },
  {
    path: PAYMENT_SUCCESS,
    element: <PaymentResolver />,
  },
  {
    path: PAYMENT_FAILED,
    element: <PaymentResolver />,
  },
  ...(process.env.NODE_ENV !== "production" ? technicalRoutes : []),
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
]);

export default router;
