import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  visibleOverlay: false,
};

export const overlaySlice = createSlice({
  name: "overlay",
  initialState,
  reducers: {
    setOpenOverlay: (state) => {
      state.visibleOverlay = true;
    },
    setCloseOverlay: (state) => {
      state.visibleOverlay = false;
    },
    clearOverlay: (state) => {
      state.visibleOverlay = false;
    },
  },
});

export const {setOpenOverlay, setCloseOverlay, clearOverlay} =
  overlaySlice.actions;

export const overlaySelector = (state) => state.overlay;

export const overlayReducer = overlaySlice.reducer;
