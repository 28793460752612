import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  mystery: undefined,
  loading: true,
  error: undefined,
};

export const mysterySlice = createSlice({
  name: "mystery",
  initialState,
  reducers: {
    setMysteryLoading(state) {
      state.loading = true;
    },
    setMysteryLoadingSuccess(state, action) {
      state.loading = false;
      state.mystery = action.payload;
    },
    setMysteryLoadingError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setMysteryStatus(state, action) {
      if (state.mystery && state.mystery.status) {
        state.mystery.status = action.payload.status;
      }
    },
    updateMysteryBox(state, action) {
      state.mystery = action.payload;
    },
    clearMystery() {
      return initialState;
    },
  },
});

export const {
  setMysteryLoading,
  setMysteryLoadingSuccess,
  setMysteryLoadingError,
  updateMysteryBox,
  clearMystery,
} = mysterySlice.actions;

export const mysterySelector = (state) => state.mystery;

export const mysteryReducer = mysterySlice.reducer;
